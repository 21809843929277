import client from "./client";

export function getBookings(date) {
  return client()
    .get("customer/bookings", { params: { date: date } })
    .then((response) => response.data);
}

export function getLodgings(params) {
  return client()
    .get("customer/lodgings", { params })
    .then((response) => response.data.data);
}

export function showLodging(id, date) {
  return client()
    .get(`customer/lodgings/${id}`, { params: { date: date } })
    .then((response) => response.data.data);
}

export function getPrices(lodging_id, start_date, end_date) {
  return client()
    .get("customer/prices", { params: { lodging_id: lodging_id, start_date: start_date, end_date: end_date } })
    .then((response) => response.data.data);
}

export function getAdditionalCosts(lodging_id) {
  return client()
    .get("customer/additional_costs", { params: { lodging_id: lodging_id } })
    .then((response) => response.data.data);
}

export function getDiscounts(lodging_id, discount_code) {
  return client()
    .get("customer/discounts", { params: { lodging_id: lodging_id, discount_code: discount_code } })
    .then((response) => response.data.data);
}

export function getRules(lodging_id, start_date, end_date) {
  return client()
    .get("customer/rules", { params: { lodging_id: lodging_id, start_date: start_date, end_date: end_date } })
    .then((response) => response.data.data);
}

export function createBooking(booking) {
  return client()
    .post("customer/bookings", booking)
    .then((response) => response.data.data);
}

export function showBooking(lodging_id, date) {
  return client()
    .get(`customer/bookings/${lodging_id}`, { params: { date: date } })
    .then((response) => response.data);
}
