import axios from "axios";
import Qs from "qs";
import NProgress from "nprogress/nprogress";
import store from "./index";

function requestHeaders() {
  return {
    "Content-Type": "application/json",
    "AUTH-TOKEN": getAuthToken(),
  };
}

function getAuthToken() {
  return store.getters.getAuthToken;
}

// before a request is made start the nprogress
axios.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

// intercepting the response in order to handle error
axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response && error.response.data) return Promise.reject(error.response.data);
      return Promise.reject(error.message);
    } else {
      if (error.response && error.response.data) return Promise.reject(error.response.data);
      return Promise.reject(error.message);
    }
  }
);

const client = () =>
  axios.create({
    baseURL: `${location.protocol}//${window.location.host.split(".")[0]}.${process.env.VUE_APP_API_URL}`,
    headers: requestHeaders(),
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "brackets" }),
  });

export default client;
